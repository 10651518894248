import { useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import fileSaver from "file-saver";
import authHeader from "../helpers/header";
import helper from "../helpers/query";

import { API_URL, NODE_URL } from "../configs";

// send preview file to upload *** OLD METHOD ***
const uploadPreview = async (data) => {
  const response = await axios.post(`${API_URL}files/upload`, data, {
    crossorigin: true
  });
  return response.data;
};

// send preview image to upload
//const useUploadForm = (url: string) => {
const useUploadPreview = () => {
  //const [isSuccess, setIsSuccess] = useState(false);
  const [previewProgress, setPreviewProgress] = useState(0);
  const [previewUploadError, setPreviewUploadError] = useState();

  const previewProcess = async (formData) => {
    setPreviewUploadError("");
    return await axios.post(`${API_URL}files/upload_preview`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setPreviewProgress(Math.round((100 * data.loaded) / data.total));
      }
    }).then((response) => {
      return response;
    }).catch(function(error) {
      setPreviewUploadError(error.response.data.detail);
    });
  };
  return { previewProcess, previewProgress, previewUploadError };
};

const useUploadBannerEvent = () => {
  //const [isSuccess, setIsSuccess] = useState(false);
  const [previewProgress, setPreviewProgress] = useState(0);
  const [previewUploadError, setPreviewUploadError] = useState();

  const previewProcess = async (formData) => {
    setPreviewUploadError("");
    return await axios.post(`${API_URL}files/upload_event_banner`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setPreviewProgress(Math.round((100 * data.loaded) / data.total));
      }
    }).then((response) => {
      return response;
    }).catch(function(error) {
      setPreviewUploadError(error.response.data.detail);
    });
  };
  return { previewProcess, previewProgress, previewUploadError };
};

// send file to upload *** OLD METHOD ***
const uploadFile = async (data) => {
  const response = await axios.post(`${API_URL}files/upload`, data, {
    crossorigin: true
  });
  return response.data;
};

// send file to upload
const useUploadFile = () => {
  //const [isSuccess, setIsSuccess] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [fileUploadError, setFileUploadError] = useState();

  const fileProcess = async (formData) => {
    setFileUploadError("");
    return await axios.post(`${API_URL}files/upload_file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setFileProgress(Math.round((100 * data.loaded) / data.total));
      }
    }).then((response) => {
      return response;
    }).catch(function(error) {
      setFileUploadError(error.response.data.detail);
    });
  };
  return { fileProcess, fileProgress, fileUploadError };
};

// request all file with query
const getAllFiles = async (query) => {
  let newQuery = {};

  Object.keys(query).map((item) => {
    if (query[item]) {
      switch (item) {
        case "page":
          newQuery.offset = parseInt(query.page) - 1;
          break;
        case "pageSize":
          newQuery.limit = parseInt(query.pageSize);
          break;
        default:
          newQuery[item] = query[item];
          break;
      }
    }
  });

  let q = helper.arrayToQueryString(newQuery);

  const response = await axios.get(API_URL + `files/?${q}`);
  return response.data;
};

// get all ai files
const getAiFiles = async (query) => {
  let newQuery = {
    // format: "ai",
  };

  Object.keys(query).map((item) => {
    if (query[item]) {
      switch (item) {
        case "page":
          newQuery.offset = parseInt(query.page) - 1;
          break;
        case "pageSize":
          newQuery.limit = parseInt(query.pageSize);
          break;
        default:
          newQuery[item] = query[item];
          break;
      }
    }
  });

  let q = helper.arrayToQueryString(newQuery);
  const response = await axios.get(
    `${NODE_URL}/api/resource/getAllAiFiles?${q}`
  );
  return response.data;
};

// get resource files
const getResourceFiles = (resourceId) => {
  return axios.get(API_URL + `files/by_resource/${resourceId}`);
};

// get files from same resource
const getFilesSameResources = (fileId) => {
  return axios.get(API_URL + `files/related_resource/${fileId}`);
};
// send create resource request
const createResourceFile = (data) => {
  return axios.post(
    API_URL + "files",
    {
      filename: data.filename,
      resourceId: data.resourceId,
      path: data.path,
      aws_path: data.aws_path,
      format: data.format,
      file_size: data.file_size
    },
    { headers: authHeader() }
  );
};

// send delete request
const deleteFile = (fileId) => {
  return axios.delete(API_URL + `files/${fileId}`);
};
// active a file
const updateFile = (fileId, data) => {
  return axios.put(API_URL + `files/${fileId}`, { data });
};

const scanAiFile = async (id) => {
  const res = await axios.get(`${NODE_URL}/api/resource/scanAi/${id}`);
  return res;
};

// scan all ai files
const scanAllAiFiles = async () => {
  const res = await axios.get(`${NODE_URL}/api/resource/scanAllAiFiles`);
  return res;
};

/**
 * get file detail by file_id
 * @param {str} file_id
 * @returns json data
 */
const getFileById = async (file_id) => {
  const res = await axios.get(API_URL + `files/${file_id}`);
  return res;
};

/**
 * check if file storage path death or alive
 * @param {str} file_id
 * @returns json data
 */
const checkFileExist = async (file_id) => {
  const res = await axios.get(API_URL + `files/checkexist${file_id}`);
  return res;
};

/**
 * convert file ai to eps
 * @param {str} fileai_id
 * @returns json data
 */
const convertAItoEPS = async (file_id) => {
  const res = await axios.get(
    `${NODE_URL}/api/resource/convert/ai-eps/by_resource_id/${file_id}`
  );
  return res;
};

/**
 * convert file ai to eps
 * @param {str} fileai_id
 * @returns json data
 */
const convertAItoSVG = async (file_id) => {
  const res = await axios.get(
    `${NODE_URL}/api/resource/convert/ai-svg/by_resource_id/${file_id}`
  );
  return res;
};

/**
 * download file from AWS
 * @param {str} filepath
 * @param {str} filename
 * @returns blob data
 */
// const downloadS3 = (filepath, filename) => {
//   const s3 = new AWS.S3();
//   s3.config.update({
//     accessKeyId: S3_KEY,
//     secretAccessKey: S3_SECRET,
//     region: S3_REGION,
//     endpoint: `s3-${S3_REGION}.amazonaws.com`,
//     signatureVersion: "v4",
//   });
//   console.log({
//     accessKeyId: S3_KEY,
//     secretAccessKey: S3_SECRET,
//     region: S3_REGION,
//     endpoint: `s3-${S3_REGION}.amazonaws.com`,
//     signatureVersion: "v4",
//   })
//   const params = { Bucket: S3_BUCKET, Key: filepath };
//   s3.getSignedUrl("getObject", params, function (err, url) {
//     // console.log("Your generated pre-signed URL is", url);
//     axios.get(`${url}`, { responseType: "blob" }).then((res) => {
//       const blob = new File([res.data], `${filename}`);
//       fileSaver.saveAs(blob);
//     });
//   });
// };

const exportedObject = {
  uploadPreview,
  uploadFile,
  deleteFile,
  updateFile,
  createResourceFile,
  getResourceFiles,
  getFilesSameResources,
  getAllFiles,
  useUploadPreview,
  useUploadFile,
  scanAiFile,
  getAiFiles,
  scanAllAiFiles,
  getFileById,
  checkFileExist,
  convertAItoEPS,
  convertAItoSVG,
  useUploadBannerEvent
  //downloadS3
};

export default exportedObject;
