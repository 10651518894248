import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
//import { useForm } from "react-hook-form";
import Moment from "react-moment";
import TimeAgo from "react-timeago";
import { connect, useDispatch } from "react-redux";
import ResourceAPI from "../../api/resourceAPI";
import CollectionAPI from "../../api/collectionAPI";
import FileAPI from "../../api/fileAPI";
import UserAPI from "../../api/userAPI";

import ResourceFiles from "../../components/Resource/Files";
import PreviewImage from "../../components/Resource/PreviewImage";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { setMessage } from "../../redux/actions/message";

import format from "../../helpers/format";
import { MEDIA_URL, FE_WEBSITE_URL } from "../../configs";

//export default function ResourceDetail(props) {
function ResourceDetail(props) {
  const { id } = useParams();

  //copy text state
  const textSlugRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("");

  const [isLoading, setLoading] = useState(true);
  const [resource, setResource] = useState([]);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [rejectionReason, setRejectionReason] = useState({});
  const [rejectByUser, setRejectByUser] = useState({});
  const [collections, setCollections] = useState([]);
  const [similarResources, setSimilarResources] = useState([]);
  const dispatch = useDispatch();
  const { token, user, message, messageType } = props;

  const [show, toggleShow] = useState(true);

  // This is the function we wrote earlier
  function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  function copyToClipboard(e) {
    //console.log(textSlugRef.current.value)
    let txt = textSlugRef.current.innerText;
    // console.log(txt);
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(txt);
    } else {
      document.execCommand("copy", true, txt);
    }
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    //e.target.focus();
    setCopySuccess("Copied!");
  }

  // fetch resource data
  const fetchData = async (id) => {
    try {
      const { data } = await ResourceAPI.getResourceById(id);
      if (data.status === "reject") {
        const { data: rejection } = await ResourceAPI.getRejectionReasonById(
          id
        );
        const reject_by = await UserAPI.getUser(rejection.rejected_by);
        setRejectionReason(rejection);
        setRejectByUser(reject_by);
      }
      setResource(data);
    } catch (error) {
      console.log("load data error", error);
    }
    setLoading(false);
  };

  /**
   * get resources has same collection
   * @param {str} resourceId
   */

  const getSimilarResources = async (resourceId) => {
    await ResourceAPI.getSameCollectionbyResourceId(resourceId)
      .then((response) => {
        // console.log(response.data);
        setSimilarResources(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /**
   * get resources has same collection
   * @param {str} resourceId
   */

  const getCollectionByResource = async (resourceId) => {
    await CollectionAPI.getCollectionHasResource(resourceId)
      .then((response) => {
        // console.log(response.data);
        setCollections(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /**
   * generate resource color base preview
   * @param {str} resourceId
   */
  const generateThumbnail = async (resourceId) => {
    await ResourceAPI.generateResourceThumbnail(resourceId)
      .then((response) => {
        // console.log(response.data);
        dispatch(setMessage(`${response.data}`, "success"));
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  /**
   * generate resource missing data
   * @param {str} resourceId
   */
  const reGenerateData = async (resourceId) => {
    await ResourceAPI.generateResourceData(resourceId)
      .then((response) => {
        // console.log(response.data);
        dispatch(setMessage(`${response.data}`, "success"));
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  /**
   * generate resource color base preview
   * @param {str} resourceId
   */
  const generateColors = async (resourceId) => {
    setLoading(true);
    await ResourceAPI.generateResourceColors(resourceId)
      .then((response) => {
        // console.log(response.data);
        dispatch(setMessage(`${response.data}`, "success"));
        setLoading(false);
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
        setLoading(false);
      });
  };

  const fetchFiles = async (resourceId) => {
    await FileAPI.getResourceFiles(resourceId)
      .then((response) => {
        setResourceFiles(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    (async () => {
      await fetchData(id);
      await fetchFiles(id);
      await getSimilarResources(id);
      await getCollectionByResource(id);
    })();
  }, [isLoading]);

  return (
    <AuthLayout>
      {isLoading ? (
        <>
          <p>loading</p>
        </>
      ) : (
        <>
          <header
            className={
              "d-md-flex d-sm-inline justify-content-between align-items-center mb-4 gap-3"
            }
          >
            <div className="d-flex align-items-center">
              <h2 className="mb-2 me-2">{resource.name}</h2>
              <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                {resource.slug?.split("-").pop()}
              </small>
            </div>
            <div className="text-right d-flex gap-2">
              <Link
                className={"btn btn-primary"}
                to={`/resources/edit/${resource._id}`}
              >
                Edit
              </Link>
              <button
                className={"btn btn-outline-success text-nowrap"}
                onClick={() => {
                  reGenerateData(resource._id);
                }}
              >
                Generate data
              </button>
              <Link
                to={`/resources/convert-file/?resources_id=${resource._id}`}
                className="btn btn-secondary"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-arrow-left-right"></i>
              </Link>
            </div>
          </header>
          {message && (
            <div className="mb-4">
              <div className={`alert alert-${messageType}`} role="alert">
                {message}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-5">
              <dl className="row mb-5">
                <dt className="col-sm-3 fw-normal text-muted mb-2">Status</dt>
                <dd className="col-sm-9">
                  <span className="text-capitalize">{format.formatResourceStatus(resource.status)}</span>
                  {
                    resource.status == 'reject' && rejectionReason &&
                    <span className="mx-3 text-normal text-danger">
                      {rejectionReason.rejection_reason}
                    </span>
                  }
                </dd>
          
                <dt className="col-sm-3 fw-normal text-muted mb-2">License</dt>
                <dd className="col-sm-9 small">
                  <span className="badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                    {resource.license.toUpperCase()}
                  </span>
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Picked</dt>
                <dd className="col-sm-9 small">
                  {resource.is_picked ? (
                    <i className="bi bi-check fs-5 text-sucess d-flex"></i>
                  ) : (
                    <i className="bi bi-x fs-5 d-flex"></i>
                  )}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Type</dt>
                <dd className="col-sm-9 text-capitalize">
                  <span className="badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                    {resource.contentType.toUpperCase()}
                  </span>
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Creator</dt>
                <dd className="col-sm-9">
                  {resource.creator.email}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/resources/?created_by=${resource.creator.username}`}
                    className="p-1 text-muted d-inline-flex"
                  >
                    <i className="bi bi-grid"></i>
                  </a>
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Created</dt>
                <dd className="col-sm-9">
                  <Moment format="YYYY-MM-DD HH:mm:ss">
                    {resource.created_at}
                  </Moment>
                  <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                    <i className="bi bi-clock me-1"></i>
                    <span className="">
                      <TimeAgo date={resource.created_at} />
                    </span>
                  </span>
                  {/* {new Date(resource.created_at).toDateString()} */}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">
                  Last Update
                </dt>
                <dd className="col-sm-9">
                  {resource.updated_at && (
                    <>
                      <Moment format="YYYY-MM-DD HH:mm:ss">
                        {resource.updated_at}
                      </Moment>
                      <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                        <i className="bi bi-clock me-1"></i>
                        <span className="">
                          <TimeAgo date={resource.updated_at} />
                        </span>
                      </span>
                    </>
                  )}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">
                  Description
                </dt>
                <dd className="col-sm-9 mb-2">{resource.description}</dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">
                  Orientation
                </dt>
                <dd className="col-sm-9">
                  {resource.orientation && (
                    <>
                      {format.formatResourceOrientation(
                        resource.orientation,
                        true
                      )}
                    </>
                  )}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Colors</dt>
                <dd className="col-sm-9 fw-normal d-flex align-items-center">
                  {resource.colors
                    ? resource.colors.map((color, colorIndex) => {
                        return (
                          <div
                            key={colorIndex + 1}
                            style={{
                              backgroundColor: `rgb(${color[0]},${color[1]},${color[2]})`,
                              width: "30px",
                              height: "15px",
                            }}
                          ></div>
                        );
                      })
                    : ""}
                  <button
                    className="btn btn-sm ms-2"
                    onClick={() => {
                      generateColors(resource._id);
                    }}
                  >
                    <i className="bi bi-palette-fill text-muted"></i>
                  </button>
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Tags</dt>
                <dd className="col-sm-9">
                  {resource.tags &&
                    resource.tags.length > 0 &&
                    resource.tags.map((tag, i) => {
                      return (
                        <span
                          className="badge bg-white border rounded me-2 text-dark fw-normal fs-6 my-1 py-2 px-3 suggested-tag"
                          key={i}
                        >
                          {tag.name}
                          {/* {i < resource.tags.length - 1 && ','} */}
                        </span>
                      );
                    })}
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">Slug</dt>
                <dd className="col-sm-9">
                  <span ref={textSlugRef}>{resource.slug}</span>
                  {/* <input name="slug" type="text" className="d-none" value={resource.slug} ref={textSlugRef} /> */}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${FE_WEBSITE_URL}vector/${resource.slug}`}
                    className="ms-4 me-1 small px-2 py-1 text-muted btn btn-link"
                  >
                    <i className="bi bi-link fs-5"></i>
                  </a>
                  <span
                    className="mx-1 px-2 py-1 small text-muted btn btn-link"
                    onClick={copyToClipboard}
                  >
                    {!copySuccess ? (
                      <i className="bi bi-clipboard fs-6"></i>
                    ) : (
                      <>
                        <i className="bi bi-check text-success fs-6"></i>
                        <span className="ms-2">{copySuccess}</span>
                      </>
                    )}
                  </span>
                </dd>
                <dt className="col-sm-3 fw-normal text-muted mb-2">
                  Canonical
                </dt>
                <dd className="col-sm-9 mb-2">
                  {resource.metaSeo && resource.metaSeo.canonicalURL
                    ? resource.metaSeo.canonicalURL
                    : ""}
                </dd>
                {/* <dt className="col-sm-2 fw-normal text-muted mb-2">
                  Categories
                </dt>
                <dd className="col-sm-10">
                  {resource.categories &&
                    resource.categories.length > 0 &&
                    resource.categories.map((category, i) => {
                      return (
                        <span className="text-muted" key={i}>
                          {category.name}
                          {i < resource.categories.length - 1 && ","}
                        </span>
                      );
                    })}
                </dd> */}
                <dt className="col-sm-3 fw-normal text-muted mb-2">Styles</dt>
                <dd className="col-sm-9">
                  {resource.styles &&
                    resource.styles.length > 0 &&
                    resource.styles.map((style, i) => {
                      return (
                        <span className="text-muted" key={i}>
                          {style}
                          {i < resource.styles.length - 1 && ","}
                        </span>
                      );
                    })}
                </dd>
              </dl>
              {resource.metaSeo && (
                <>
                  <p className="mb-4">
                    <strong>Meta SEO</strong>
                  </p>
                  <dl className="row mb-5">
                    <dt className="col-sm-3 fw-normal text-muted mb-2">
                      Keyword
                    </dt>
                    <dd className="col-sm-9 mb-2">
                      {resource.metaSeo && resource.metaSeo.metaKeywords
                        ? resource.metaSeo.metaKeywords
                        : ""}
                    </dd>
                    <dt className="col-sm-3 fw-normal text-muted mb-2">
                      Title
                    </dt>
                    <dd className="col-sm-9 mb-2">
                      {resource.metaSeo && resource.metaSeo.metaTitle
                        ? resource.metaSeo.metaTitle
                        : ""}
                    </dd>
                    <dt className="col-sm-3 fw-normal text-muted mb-2">
                      Description
                    </dt>
                    <dd className="col-sm-9 mb-2">
                      {resource.metaSeo && resource.metaSeo.metaDescription
                        ? resource.metaSeo.metaDescription
                        : ""}
                    </dd>
                  </dl>
                </>
              )}

              {resourceFiles && (
                <>
                  <h6 className="mb-4">Other files from same resources</h6>
                  <ResourceFiles resourceFiles={resourceFiles} />
                </>
              )}
              {/* collection */}
              {collections && collections.length > 0 && (
                <>
                  <h6 className="mt-8 pt-4 pb-2">
                    {"Collections with resource"}
                  </h6>
                </>
              )}
              {collections &&
                collections.map((collection, colIndex) => {
                  return (
                    <div
                      key={colIndex}
                      className="mb-3 d-flex justify-content-start align-items-center"
                    >
                      <div className="me-4 text-muted">
                        <i className="bi bi-stack"></i>
                      </div>
                      <div className="me-4">
                        <p className="mb-1">
                          <Link to={`/collections/view/${collection._id}`}>
                            {collection.collection_name}
                          </Link>
                        </p>
                        <p>
                          <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2 py-1">
                            {collection.owner.email}
                          </small>
                          <Moment
                            format="YYYY-MM-DD"
                            className="small rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2 ms-1 py-1"
                          >
                            <span>{collection.created_at}</span>
                          </Moment>
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="col-md-6 py-4 ms-auto text-left">
              {resource.images.length > 0 && (
                <div className="d-flex bg-gray">
                  {resource.images.map((preview, index) => {
                    return (
                      <div className="text-center" key={index}>
                        <div className="shadow mx-2 position-relative">
                          <PreviewImage
                            image={`${MEDIA_URL}${preview.sizes[0].path}`}
                            index={index}
                            width={400}
                            disable={true}
                          />

                          {/* {mainImage !== index && (
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 border-0 bg-transparent"
                              onClick={() => removePreview(preview.filename)}
                            >
                              <i className="bi bi-x-circle-fill"></i>
                            </button>
                          )} */}
                        </div>
                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">
                          {preview.sizes[0].contentType}
                        </span>
                        {preview.sizes[0] && (
                          <>
                            <div className="badge bg-secondary bg-opacity-10 text-secondary rounded-pill fw-normal pt-1 text-uppercase small mx-1 my-2">
                              <i className="bi bi-grid-1x2"></i>{" "}
                              {preview.sizes[0].width +
                                " x " +
                                preview.sizes[0].height}
                            </div>
                          </>
                        )}
                        {preview.sizes[1] &&
                          preview.sizes[1].size === "small" && (
                            <a
                              href={`${MEDIA_URL}${preview.sizes[1].path}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="badge bg-secondary bg-opacity-10 text-secondary rounded-pill fw-normal pt-1 text-uppercase small mx-1 my-2">
                                <i className="bi bi-grid"></i>{" "}
                                {preview.sizes[1].width +
                                  " x " +
                                  preview.sizes[1].height}
                              </div>
                            </a>
                          )}
                        {resource.mainImage === index && (
                          <p className="pt-2 fw-semibold">
                            <small className="badge bg-success rounded-pill text-uppercase">
                              C
                            </small>
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {/* {resource.images &&
                resource.images.map((preview, p) => {
                  return (
                    <div className="w-auto bg-light p-4" key={p}>
                      <PreviewImage
                        image={`${MEDIA_URL}${preview.sizes[0].path}`}
                        width={480}
                      />
                    </div>
                  );
                })} */}
              {resource?.images?.length > 0 && (
                <div className="text-center my-3">
                  <button
                    className="btn btn-sm bg-light"
                    onClick={() => {
                      // generateColors(resource._id);
                      generateThumbnail(resource._id);
                    }}
                  >
                    <i className="bi bi-image text-muted"></i>
                    <span className="small text-muted ms-3">
                      Generate thumbnail images
                    </span>
                  </button>
                </div>
              )}
              <div>
                {similarResources && similarResources.length > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mt-8 pt-4 pb-2">
                      {`Resources with same name or collection`}
                      <span className="small text-muted">{` ( ${similarResources.length} resources )`}</span>
                    </h6>
                    <button
                      onClick={() => toggleShow(!show)}
                      className="btn btn-sm btn-light"
                    >
                      {show ? "hide" : "show"}
                    </button>
                  </div>
                )}
                {show && (
                  <>
                    {similarResources &&
                      similarResources.map((resource, index) => {
                        return (
                          <div
                            className="row align-items-center mb-2 border-0 border-end-1"
                            key={index}
                          >
                            <div className="col text-left">
                              {resource?.thumbnail && (
                                <>
                                  <Link to={`/resources/view/${resource._id}`}>
                                    <img
                                      src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                      height={64}
                                      className="mx-auto my-2 img-fluid px-8 small-thumb"
                                    />
                                    {/* <PreviewImage image={`${MEDIA_URL}${resource.resourcePreview[0].path}`} style={{width:`100%`}} /> */}
                                  </Link>
                                </>
                              )}
                            </div>
                            <div className="col-md-8">
                              <p className="mb-1 list-title">
                                <Link
                                  to={`/resources/view/${resource._id}`}
                                  className="text-dark"
                                >
                                  {resource.name}
                                </Link>
                              </p>
                              <p className="mb-1">
                                {format.formatResourceStatusNoText(
                                  resource.status
                                )}
                                <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2 py-1 ms-2">
                                  {resource.slug.split("-").pop()}
                                </small>
                                {resource.metaSeo &&
                                resource.metaSeo.canonicalURL ? (
                                  <>
                                    <span className="badge bg-primary rounded-pill ms-2">
                                      {"C"}
                                    </span>
                                    <span className="badge bg-light ms-1 text-dark">
                                      {resource.metaSeo.canonicalURL
                                        .split("-")
                                        .pop()}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                                {resource.metaSeo &&
                                resource.metaSeo.redirectURL ? (
                                  <>
                                    <span className="badge bg-danger rounded-pill ms-2">
                                      {"R"}
                                    </span>
                                    <span className="badge bg-light ms-1 text-dark">
                                      {resource.metaSeo.redirectURL
                                        .split("-")
                                        .pop()}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="col-md-2">
                              <Link
                                to={`/resources/edit/${resource._id}`}
                                className="btn btn-light rounded-pill"
                              >
                                <i className="bi bi-pencil text-muted"></i>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* end html row*/}
        </>
      )}
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;

  const { message, messageType } = state.message;
  return {
    token,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(ResourceDetail);
