import { memo, useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import imgError from "../../assets/imgs/placeholder.png";

function PreviewImage({
  image,
  width,
  height,
  alt = "",
  index,
  disable = false,
  mainImage,
  setMainImage
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleShow = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleMainImage = (index) => {
    setMainImage(index);
    setIsOpen(false);
  };

  useEffect(() => {
    // setLoading(false);
    return () => setImageError(false);
  }, [image]);

  return (
    <>
      {image ? (
        <>
          <div
            className="text-center cursor-pointer p-2"
            style={{
              height: height ? height : "auto",
              width: height ? height : "auto"
            }}
          >
            <LazyLoadImage
              alt={alt}
              width={!height ? width : "auto"}
              height={height ? height - 16 : "auto"}
              src={imageError ? imgError : image}
              onError={() => setImageError(true)}
              onClick={() => handleShow()}
              className="img-preview"
            />
          </div>

          <Modal show={isOpen} onHide={handleClose} animation={true} centered>
            <Modal.Body>
              <div className="d-flex position-relative light-box__content">
                <button
                  type="button"
                  className="position-absolute top-0 end-0 btn"
                  onClick={() => handleClose()}
                >
                  <i className="bi bi-x-lg fs-4"></i>
                </button>
                <img
                  src={imageError ? imgError : image}
                  alt={alt}
                  className="bg-light p-4 light-box__img"
                />
              </div>
            </Modal.Body>
            {!disable && mainImage !== index && (
              <Modal.Footer className="justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary text-capitalize"
                  onClick={() => handleMainImage(index)}
                >
                  set as cover photo
                </button>
              </Modal.Footer>
            )}
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(PreviewImage);
