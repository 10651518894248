import { Link, useLocation, useSearchParams } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import Sorter from "../../components/Sorter/Sorter";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import EventAPI from "../../api/eventAPI";
import EventItem from "../../components/Event/EventItem";

const filters = [
  {
    type: "text",
    name: "q",
    defaultValue: ""
  },
  {
    type: "datePicker",
    name: "created_from",
    placeholder: "Created date from",
    defaultValue: ""
  },
  {
    type: "datePicker",
    name: "created_to",
    placeholder: "Created date to",
    defaultValue: ""
  },
  {
    type: "select",
    name: "status",
    options: [
      { title: "Select status", value: "" },
      { title: "Active", value: "active" },
      { title: "Inactive", value: "inactive" }
    ],
    defaultValue: ""
  }
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  sortby: "",
  direction: "",
  page: ""
};

const pageSize = 48;

const EventList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { message, messageType } = useSelector(state => state.message);
  const [query, setQuery] = useState(initialQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const newQuery = Object.assign({}, query);

    Object.keys(defaultFilter).map((filter) => {
      if (searchParams.get(filter)) {
        newQuery[filter] = searchParams.get(filter);
      }
      return newQuery;
    });

    if (!searchParams.get("q")) {
      newQuery["q"] = "";
    }

    if (searchParams.get("sortby")) {
      newQuery.sortby = searchParams.get("sortby");
    }
    if (searchParams.get("direction")) {
      newQuery.direction = searchParams.get("direction");
    }
    if (searchParams.get("created_by")) {
      newQuery.created_by = searchParams.get("created_by");
    }

    if (searchParams.get("created_by")) {
      newQuery.created_by = searchParams.get("created_by");
    }
    if (searchParams.get("created_from")) {
      newQuery.created_by = searchParams.get("created_from");
    }
    if (searchParams.get("created_to")) {
      newQuery.created_by = searchParams.get("created_to");
    }
    if (searchParams.get("page")) {
      newQuery.page = searchParams.get("page");
      setCurrentPage(searchParams.get("page"));
    } else {
      newQuery.page = 1;
      setCurrentPage(1);
    }
    setQuery(newQuery);

    const fullQuery = Object.assign({}, newQuery);
    fullQuery.pageSize = pageSize;
    EventAPI.getEvents(fullQuery).then((res) => {
      setEvents(res.data.events);
      setCount(res.data.pagination.totalEvents);
    });
  }, [location]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch
    //formState: { errors },
  } = useForm();

  // const handleCheckAll = () => {
  //   if (selectedId.length === 0) {
  //     events.map((event) => {
  //       setSelectedId((selectedId) => [...selectedId, event._id]);
  //       return selectedId;
  //     });
  //   } else {
  //     setSelectedId([]);
  //   }
  // };

  const handleAddEvent = (event) => {
    setSelectedId(prev => [...prev, event]);
  };
  const handleRemoveEvent = (event) => {
    setSelectedId(prev => prev.filter(item => item._id !== event._id));
  };

  const handleReset = () => {
    setQuery(initialQuery);
  };
  return (
    <AuthLayout>
      <header className={"d-md-flex d-sm-inline justify-content-between align-items-center mb-4"}>
        <h2>{"Events"}</h2>
        <div className="d-flex align-self-end">
          <Link className={"btn btn-primary px-4 me-2"} to={`/events/new`}>
            <i className="bi bi-file-arrow-up me-2 text-white"></i>
            {"Create"}
          </Link>
        </div>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert text-break alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}
      {/* <Search/> */}
      <div className="mb-3">
        <Filter
          defaultFilter={defaultFilter}
          filters={filters}
          reset={handleReset}
        />
      </div>
      {isLoading ? (
        <p className="text-center p-5">Loading</p>
      ) : (
        <>
          {events?.length > 0 ? (
            <>
              <div className="rgrid-toolbar d-flex align-items-center justify-content-between mb-4">
                <div className="check-action d-flex align-items-center justify-content-between">
                  {/*<button*/}
                  {/*  className="btn bg-light px-3 btn-sm"*/}
                  {/*  onClick={(e) => {*/}
                  {/*    e.preventDefault();*/}
                  {/*    handleCheckAll();*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {selectedId.length > 0 ? "Unselect all" : "Select all"}*/}
                  {/*</button>*/}
                  {selectedId.length > 0 && (
                    <div className="small mx-4 text-muted">
                      {selectedId.length} {"selected"}
                    </div>
                  )}
                </div>

                <div className="rgrid-sorter d-flex align-items-center">
                  <div className="me-4 d-none d-md-block">
                    <strong>Sort by</strong>
                  </div>
                  <div className="me-4">
                    <Sorter
                      defaultFilter={defaultFilter}
                      query={query}
                      title="Name"
                      name="name"
                    />
                  </div>
                  <div className="me-4">
                    <Sorter
                      defaultFilter={defaultFilter}
                      query={query}
                      title="Created At"
                      name="created_at"
                    />
                  </div>
                </div>
              </div>
              <div className="row border-top-0 gx-2">
                {events.map((event, index) => {
                  return (
                    <EventItem
                      event={event}
                      key={index}
                      selectedId={selectedId}
                      handleAddEvent={handleAddEvent}
                      handleRemoveEvent={handleRemoveEvent}/>
                  );
                })}
              </div>

              <div className="my-4">
                <Pagination
                  count={count}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  path={"events"}
                  moduleName={"events"}
                  query={query}
                />
              </div>
            </>
          ) : (
            <p className="my-5 py-2 text-center bg-warning bg-opacity-10 text-warning rounded">
              {"No resource founded"}
            </p>
          )}
        </>
      )}
    </AuthLayout>
  );
};

export default EventList;