import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLoadPage } from "../../helpers/useLoadPage";

const Filter = ({ defaultFilter, filters, reset }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const loadPage = useLoadPage();
  const { register, handleSubmit, setValue, getValues, control } = useForm({
    defaultValue: defaultFilter
  });

  useEffect(() => {
    Object.keys(defaultFilter).map((filter) => {
      if (searchParams.get(filter)) {
        if (filter === "created_from" || filter === "created_to") {
          setValue(filter, moment(searchParams.get(filter)).toDate());
        } else {
          setValue(filter, searchParams.get(filter));
        }
      } else {
        getValues(filter) && setValue(filter, "");
      }
    });
  }, [searchParams]);

  const filterHandle = (data) => {
    let params = {};

    Object.keys(defaultFilter).map((filter) => {
      if (data[filter]) {
        params[filter] = data[filter];
      }
    });

    params.page = 1;
    loadPage(params);
  };

  const onSubmit = (data) => {

    if (data.created_from) {
      data.created_from = moment(data.created_from).format("YYYY-MM-DD");
    }
    if (data.created_to) {
      data.created_to = moment(data.created_to).format("YYYY-MM-DD");
    }

    if (Object.values(data).filter((item) => item?.trim().length > 0)) {
      filterHandle(data);
    }
  };

  const resetHandle = () => {
    reset();
    loadPage({});
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-md-flex d-sm-inline g-3 mb-4">
      {filters.map((item, index) => {
        if (item.type === "text") {
          return (
            <div className="me-3 mb-2" key={index}>
              <input
                type="text"
                {...register(item.name)}
                className="form-control"
                placeholder={item.placeholder ? item.placeholder : "Enter a keyword"}
              />
            </div>
          );
        }
        if (item.type === "select") {
          return (
            <div className="me-3 mb-2" key={index}>
              <select className="form-select" {...register(item.name)}>
                {item.options.map((option, indexOption) => (
                  <option value={option.value} key={indexOption}>
                    {option.title}
                  </option>
                ))}
              </select>
            </div>
          );
        }
        if (item.type === "datePicker") {
          return (
            <div className="me-3 mb-2" key={index}>
              <Controller
                control={control}
                name={item.name}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    className="form-control"
                    placeholderText={item.placeholder}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    dateFormat="yyyy/MM/dd"
                    showYearDropdown
                  />
                )}
              />
            </div>
          );
        }
      })}

      <div className="me-3 mb-2">
        <div className="d-flex align-items-center">
          <button type="submit" className="btn btn-secondary me-2">
            <i className="bi bi-search"></i>
          </button>
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => {
              resetHandle();
            }}
          >
            <i className="bi bi-activity"></i>
          </button>
        </div>
      </div>
    </form>
  );
};

export default Filter;
