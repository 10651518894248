import React from 'react';
import format from "../../helpers/format";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import Moment from "react-moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from "../../assets/imgs/placeholder.png";
import { MEDIA_URL } from "../../configs";

const EventItem = ({ event, selectedId, handleAddEvent, handleRemoveEvent }) => {
  const exist = selectedId.some(item => item._id === event._id);
  return (
    <div
      className={`col-2 mb-4 rgrid-item align-middle`}
    >
      <div
        className={`rcontent position-relative p-3 border ${
          exist ? "checked" : ""
        }`}
      >
        {exist ? (
          <div className="item-check position-absolute">
            <i className="bi bi-check-lg text-success fs-5"></i>
          </div>
        ) : (
          ""
        )}
        <div className="text-center">
          {event?.images.length > 0 ?
            <LazyLoadImage
              alt={event.name}
              src={`${MEDIA_URL}/${event.images[event.mainImage].sizes[0].path}`}
              height={160}
              effect="blur"
              className="img-preview"
            /> : <LazyLoadImage
              alt={event.name}
              src={placeholder}
              height={160}
              effect="blur"
              className="img-preview"
            />}

        </div>
        <div className="d-flex justify-content-start align-items-center py-1">
          <span>
            {format.formatResourceStatusNoText(event.status)}
          </span>
        </div>
        <div>
          <Link to={`/events/view/${event._id}`}>
            <strong className="rname small">
              {event.name}
            </strong>
          </Link>
          <div className="d-flex align-items-center py-1 small">
            <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
              {event.slug.split("-").pop()}
            </small>
          </div>
        </div>

        <div className="d-none rdetail p-3 position-absolute top-0 bottom-0 start-0 end-0">
          <div className="mb-1">
            <Link to={`/events/view/${event._id}`}>
              <strong className="rname small">
                {event.name}
              </strong>
            </Link>
          </div>
          <p className="small text-muted mb-1">
            {event._id}
          </p>
          <div className="d-flex align-items-center py-1 small mb-1">
            <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
              {event.slug.split("-").pop()}
            </small>
          </div>
          <div className="text-muted mb-1 small">
          </div>

          <div className="text-muted no-break mb-1 d-flex align-items-center">
            <span
              className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
              <i className="bi bi-clock me-1"></i>
              <span className="">
                <TimeAgo date={event.created_at}/>
              </span>
            </span>
            <Moment
              format="YYYY/MM/DD HH:mm:ss"
              className="small my-1"
            >
              {event.created_at}
            </Moment>

          </div>
          <div className="text-muted no-break mb-1 d-flex align-items-center">
            {event.updated_at && (
              <>
                <span
                  className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                  <i className="bi bi-clock me-1"></i>
                  <span className="">
                    <TimeAgo date={event.updated_at}/>
                  </span>
                </span>
                <Moment
                  format="YYYY/MM/DD HH:mm:ss"
                  className="small my-1"
                >
                  {event.updated_at}
                </Moment>
              </>
            )}
          </div>
        </div>
        <div
          className="d-none p-3 item-action d-flex align-items-center justify-content-center position-absolute bottom-0 start-0 end-0">
          <Link
            to={`/events/edit/${event._id}`}
            className="btn btn-sm mx-1 rounded"
          >
            <i className="bi bi-pencil text-muted"></i>
          </Link>
          <Link
            to={`/events/view/${event._id}`}
            className="btn btn-sm mx-1"
          >
            <i className="bi bi-eye text-muted"></i>
          </Link>

          {/*<div className="mx-1">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    value={event._id}*/}
          {/*    className="form-check-input"*/}
          {/*    checked={exist ? true : false}*/}
          {/*    onChange={() => {*/}
          {/*      if (exist) {*/}
          {/*        handleRemoveEvent(event);*/}
          {/*      } else {*/}
          {/*        handleAddEvent(event);*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default EventItem;