import {
    GET_USER
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? user : {};
    // ? { isLoggedIn: true, user }
    // : { isLoggedIn: false, user: null };

export default function getUserPayload(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USER:
            //console.log(state)
            return {
                ...state,
                user: payload,
            };
        default:
            return state;
    }
}