import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import FileAPI from "../../api/fileAPI";
import { connect, useDispatch } from "react-redux";
import { history } from "../../redux/store";
import {
  MEDIA_URL
} from "../../configs";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import PreviewImage from "../../components/Resource/PreviewImage";
import CollectionAPI from "../../api/collectionAPI";
import EventAPI from "../../api/eventAPI";
import Filter from "../../components/Filter/Filter";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import CollectionItem from "../../components/Colleciton/CollectionItem";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from '../../assets/imgs/placeholder.png';
import Pagination from "../../components/Pagination";
import { setMessage } from "../../redux/actions/message";

const filters = [
  {
    type: "text",
    name: "q",
    defaultValue: ""
  },
  {
    type: "text",
    name: "created_by",
    placeholder: "Created by",
    defaultValue: ""
  },
  {
    type: "datePicker",
    name: "created_from",
    placeholder: "Created date from",
    defaultValue: ""
  },
  {
    type: "datePicker",
    name: "created_to",
    placeholder: "Created date to",
    defaultValue: ""
  }
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  direction: "",
  page: ""
};

const pageSize = 30;

function EventCreate(props) {
  const { message, messageType } = props;
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      status: "active",
      priority: null,
      images: [],
      collection_ids: [],
      mainImage: 0
    }
  });

  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [collections, setCollections] = useState([]);
  const [query, setQuery] = useState(initialQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const tempPreviewRef = useRef([]);
  const [selectedId, setSelectedId] = useState([]);

  const { previewProcess, previewProgress, previewUploadError } = FileAPI.useUploadBannerEvent();

  const resetForm = () => {
    setSelectedId([]);
    tempPreviewRef.current = [];
    reset();
  };

  const onSubmit = async (data) => {
    if (selectedId.length < 1) {
      dispatch(setMessage("Collection IDs must be a non-empty array", "danger"));
      return;
    }
    if (data.images.length < 1) {
      dispatch(setMessage("Please upload banner image for event", "danger"));
      return;
    }

    const collection_ids = selectedId.map((item) => item._id);
    const event = {
      collection_ids,
      name: data.name,
      description: data.description,
      status: data.status,
      priority: data.priority ? data.priority : -1,
      images: data.images,
      mainImage: data.mainImage
    };
    EventAPI.createEvent(event).then(res => {
      resetForm();
      dispatch(setMessage("Event created successfully", "success"));
    }).catch((err) => {
      dispatch(setMessage(err.response.data.error, "danger"));
    });
  };

  const handleReset = () => {
    setQuery(initialQuery);
  };

  const handlePreview = async (files) => {
    let tempResult = [];
    let filtedFiles = [];
    if (tempPreviewRef.current) {
      filtedFiles = [...files].filter(
        (obj1) =>
          !tempPreviewRef.current.some((obj2) => obj1.name === obj2.name)
      );
      tempPreviewRef.current = [...tempPreviewRef.current, ...filtedFiles];
    } else {
      filtedFiles = [...files];
      tempPreviewRef.current = [...files];
    }

    if (filtedFiles.length) {
      await Promise.all(
        filtedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append("data", file);
          const { data } = await previewProcess(formData);

          if (data) {
            tempPreviewRef.current.map((item) => {
              if (item.name === file.name) {
                item.filename = data.detail.filename;
                return item;
              } else {
                return item;
              }
            });

            const newPreview = {
              filename: data.detail.filename,
              sizes: [
                {
                  size: "original",
                  path: data.detail.original.path,
                  contentType: data.detail.original.contentType,
                  width: data.detail.original.width,
                  height: data.detail.original.height
                }
              ]
            };
            if (data.detail.thumbnail) {
              newPreview.sizes.push(
                {
                  size: "small",
                  path: data.detail.thumbnail.path,
                  contentType: data.detail.thumbnail.contentType,
                  width: data.detail.thumbnail.width,
                  height: data.detail.thumbnail.height
                }
              );
            }
            tempResult.push(newPreview);
          }
        })
      );
      setValue("images", [...getValues("images"), ...tempResult]);
    }
  };

  useEffect(() => {
    (async () => {
      // await fetchCategories();
      const newQuery = Object.assign({}, query);

      Object.keys(defaultFilter).map((filter) => {
        if (searchParams.get(filter)) {
          newQuery[filter] = searchParams.get(filter);
        }
        return newQuery;
      });

      if (!searchParams.get("q")) {
        newQuery["q"] = null;
      }

      if (searchParams.get("created_by")) {
        newQuery.created_by = searchParams.get("created_by");
      } else {
        newQuery.created_by = null;
      }
      if (searchParams.get("created_from")) {
        newQuery.created_from = searchParams.get("created_from");
      } else {
        newQuery.created_from = null;
      }
      if (searchParams.get("created_to")) {
        newQuery.created_to = searchParams.get("created_to");
      } else {
        newQuery.created_to = null;
      }

      if (searchParams.get("page")) {
        newQuery.page = searchParams.get("page");
        setCurrentPage(searchParams.get("page"));
      } else {
        newQuery.page = null;
        setCurrentPage(1);
      }
      setQuery(newQuery);

      const fullQuery = Object.assign({}, newQuery);
      fullQuery.pageSize = pageSize;
      setIsLoading(true);
      await CollectionAPI.getCollectionsByQuery(fullQuery).then(response => {
        setCollections(response.data.collections);
        setTotal(response.data.total);
      });
      setIsLoading(false);
    })();
  }, [location]);

  const handleAddCollection = (collection) => {
    setSelectedId(prev => [...prev, collection]);
  };
  const handleRemoveCollection = (collection) => {
    setSelectedId(prev => prev.filter(item => item._id !== collection._id));
  };

  const removePreview = (index) => {
    const bannerImages = getValues("images");
    const removedPreviews = bannerImages.filter((_, i) => i !== index);
    setValue("images", removedPreviews);
    if (index < getValues("mainImage")) {
      setValue("mainImage", (prev) => prev - 1);
    }
  };

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h1>{"Create Event"}</h1>
        <button className="btn btn-outline-secondary" onClick={history.back}>
          Go Back
        </button>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}

      <div className="row">

        <div className="col-md-3 me-auto">
          <h3>Info</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Event name
              </label>
              <input
                id="name"
                className="form-control"
                defaultValue={""}
                placeholder={"Enter resource name"}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="small text-danger">
                  This field is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Event description
              </label>
              <textarea
                id="name"
                className="form-control"
                rows={5}
                defaultValue={""}
                placeholder={"Enter event description"}
                {...register("description", { required: true })}
              />
              {errors.description && (
                <span className="small text-danger">
                  This field is required
                </span>
              )}
            </div>

            <div className="row">

              <div className="col-md-4 mb-3">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select className="form-select" {...register("status")}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              {watch("status") === "active" &&
                <div className="col-md-4 mb-3">
                  <label htmlFor="priority" className="form-label">
                    Priority
                  </label>
                  <input className="form-control" {...register("priority", {
                    required: watch("status") === "active" ? "Priority is required" : false
                  })}/>
                  {errors.priority && (
                    <span className="small text-danger">
                     {errors.priority.message}
                    </span>
                  )}
                </div>
              }
            </div>
            <div className="mb-3">
              <label htmlFor="rfile" className="form-label">
                Cover image
              </label>
              <input
                className="form-control"
                type="hidden"
                {...register("uploaded_preview")}
              />
              <Dropzone
                onDrop={(acceptedFiles) => handlePreview(acceptedFiles)}
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg", ".jpeg"]
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="bg-light text-center p-4 border rounded">
                    <div {...getRootProps()}>
                      <input id="rfile" {...getInputProps()} />
                      <span className="text-muted small">
                        Drag 'n' drop some files here, or click to select files
                      </span>
                    </div>
                  </section>
                )}
              </Dropzone>
              {watch("images").length > 0 && (
                <div className="d-flex bg-gray mt-4">
                  {watch("images").map((preview, index) => (
                    <div className="text-center" key={index}>
                      <div className="shadow mx-2 position-relative">
                        <PreviewImage
                          image={`${MEDIA_URL}${preview.sizes[0].path}`}
                          index={index}
                          width={200}
                          height={100}
                          mainImage={watch("mainImage")}
                          setMainImage={(value) => setValue("mainImage", value)}
                          alt={preview.filename}
                        />
                        {watch("mainImage") !== index && (
                          <button
                            type="button"
                            className="position-absolute top-0 end-0 border-0 bg-transparent"
                            onClick={() => removePreview(index)}
                          >
                            <i className="bi bi-x-circle-fill"></i>
                          </button>
                        )}
                      </div>
                      {watch("mainImage") === index && (
                        <p className="pt-2 fw-semibold">Cover Image</p>
                      )}
                    </div>
                  ))}
                </div>
              )}

            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              Create
            </button>
          </form>
        </div>
        <div className="col-md-6">
          <h3>Collections</h3>
          <div className="mb-3">
            <Filter
              defaultFilter={defaultFilter}
              filters={filters}
              reset={handleReset}
            />
          </div>
          {isLoading ? (<p className="text-center">Loading...</p>) : (collections?.length > 0 ?
              <div className="row">
                {collections.map((collection, index) =>
                  <CollectionItem
                    key={index}
                    collection={collection}
                    selectedId={selectedId}
                    handleAddCollection={handleAddCollection}
                    handleRemoveCollection={handleRemoveCollection}
                  />)}
                <div className="my-4">
                  <Pagination
                    count={total}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    path={"collections"}
                    moduleName={"collections"}
                    query={query}
                  />
                </div>
              </div>
              : <p className="my-5 py-2 text-center bg-warning bg-opacity-10 text-warning rounded">
                {"No resource founded"}
              </p>
          )}

        </div>
        <div className="col-md-3">
          <h3>Selected Collections</h3>
          <div>
            {selectedId?.map((collection, index) =>
              <div key={index} className="row align-items-center">
                <div className="col-md-4">
                  <LazyLoadImage
                    src={placeholder}
                    alt={collection.collection_name}
                    effect="blur"
                    className="img-preview"/>
                </div>
                <div className="col-md-7">
                  <Link to={`/collections/view/${collection._id}`}>
                    {collection.collection_name}
                  </Link>
                </div>
                <div className="col-md-1 px-0">
                  <button
                    type="button"
                    className="btn btn-sm mx-1"
                    onClick={() => {
                      handleRemoveCollection(collection);
                    }}
                  >
                    <i className="bi bi-trash2 text-muted"></i>
                  </button>
                </div>

              </div>
            )}
          </div>
        </div>
      </div>

    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  const { message, messageType } = state.message;
  return {
    token,
    user,
    message,
    messageType
  };
}

export default connect(mapStateToProps)(EventCreate);
