import React from 'react';
import format from "../../helpers/format";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import Moment from "react-moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from "../../assets/imgs/placeholder.png";

const CollectionItem = ({ collection, selectedId, handleAddCollection, handleRemoveCollection }) => {
  const exist = selectedId.some(item => item._id === collection._id);
  return (
    <div
      className={`col-4 mb-4 rgrid-item align-middle`}
    >
      <div
        className={`rcontent position-relative p-3 border ${
          exist ? "checked" : ""
        }`}
      >
        {exist ? (
          <div className="item-check position-absolute">
            <i className="bi bi-check-lg text-success fs-5"></i>
          </div>
        ) : (
          ""
        )}
        <div className="text-center">
          <LazyLoadImage
            alt={collection.collection_name}
            src={placeholder}
            height={160}
            effect="blur"
            className="img-preview"
          />
        </div>
        <div className="d-flex justify-content-start align-items-center py-1">
          <span>
            {format.formatResourceStatusNoText(collection.status)}
          </span>
          <div className="small mt-1 ms-2">
            <span className="ms-2">
              {format.formatResourceOrientation(
                collection.orientation
              )}
            </span>
          </div>
        </div>
        <div>
          <Link to={`/collections/view/${collection._id}`}>
            <strong className="rname small">
              {collection.collection_name}
            </strong>
          </Link>
          <div className="d-flex align-items-center py-1 small">
            <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
              {collection.slug.split("-").pop()}
            </small>
          </div>
        </div>

        <div className="d-none rdetail p-3 position-absolute top-0 bottom-0 start-0 end-0">
          <div className="mb-1">
            <Link to={`/collections/view/${collection._id}`}>
              <strong className="rname small">
                {collection.collection_name}
              </strong>
            </Link>
          </div>
          <p className="small text-muted mb-1">
            {collection._id}
          </p>
          <div className="d-flex align-items-center py-1 small mb-1">
            <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
              {collection.slug.split("-").pop()}
            </small>
          </div>
          <div className="text-muted mb-1 small">
            <Link to={`/events/new?created_by=${collection.owner}`}>
              {collection.owner}
            </Link>
          </div>

          <div className="text-muted no-break mb-1 d-flex align-items-center">
            <span
              className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
              <i className="bi bi-clock me-1"></i>
              <span className="">
                <TimeAgo date={collection.created_at}/>
              </span>
            </span>
            <Moment
              format="YYYY/MM/DD HH:mm:ss"
              className="small my-1"
            >
              {collection.created_at}
            </Moment>

          </div>
          <div className="text-muted no-break mb-1 d-flex align-items-center">
            {collection.updated_at && (
              <>
                <span
                  className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                  <i className="bi bi-clock me-1"></i>
                  <span className="">
                    <TimeAgo date={collection.updated_at}/>
                  </span>
                </span>
                <Moment
                  format="YYYY/MM/DD HH:mm:ss"
                  className="small my-1"
                >
                  {collection.updated_at}
                </Moment>
              </>
            )}
          </div>
        </div>
        <div
          className="d-none p-3 item-action d-flex align-items-center justify-content-between position-absolute bottom-0 start-0 end-0">
          <Link
            target={`_blank`}
            to={`/collections/edit/${collection._id}`}
            className="btn btn-sm mx-1 rounded"
          >
            <i className="bi bi-pencil text-muted"></i>
          </Link>
          <Link
            target={`_blank`}
            to={`/collections/view/${collection._id}`}
            className="btn btn-sm mx-1"
          >
            <i className="bi bi-eye text-muted"></i>
          </Link>

          <div className="mx-1">
            <input
              type="checkbox"
              value={collection._id}
              className="form-check-input"
              checked={exist ? true : false}
              onChange={() => {
                if (exist) {
                  handleRemoveCollection(collection);
                } else {
                  handleAddCollection(collection);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;