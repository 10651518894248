import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";
import TimeAgo from "react-timeago";
import { connect, useDispatch } from "react-redux";

import PreviewImage from "../../components/Resource/PreviewImage";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import format from "../../helpers/format";
import { MEDIA_URL, FE_WEBSITE_URL } from "../../configs";
import EventAPI from "../../api/eventAPI";
import placeholder from "../../assets/imgs/placeholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

function EventDetail(props) {
  const { id } = useParams();

  //copy text state
  const textSlugRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [event, setEvent] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const { message, messageType } = props;

  function copyToClipboard(e) {
    //console.log(textSlugRef.current.value)
    let txt = textSlugRef.current.innerText;
    // console.log(txt);
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(txt);
    } else {
      document.execCommand("copy", true, txt);
    }
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    //e.target.focus();
    setCopySuccess("Copied!");
  }

  // fetch resource data
  const fetchData = async (id) => {
    try {
      const { data } = await EventAPI.getEventDetail(id);
      setEvent(data);

      const { data: dataCollections } = await EventAPI.getCollectionOfEvent(id);
      setCollections(dataCollections);
    } catch (error) {
      console.log("load data error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData(id);
    })();
  }, [isLoading]);

  return (
    <AuthLayout>
      {isLoading ? (
        <>
          <p>Loading</p>
        </>
      ) : (event ?
          <>
            <header
              className={
                "d-md-flex d-sm-inline justify-content-between align-items-center mb-4 gap-3"
              }
            >
              <div className="d-flex align-items-center">
                <h2 className="mb-2 me-2">{event.name}</h2>
                <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                  {event.slug?.split("-").pop()}
                </small>
              </div>
              <div className="text-right d-flex gap-2">
                <Link
                  className={"btn btn-primary"}
                  to={`/events/edit/${event._id}`}
                >
                  Edit
                </Link>
              </div>
            </header>
            {message && (
              <div className="mb-4">
                <div className={`alert alert-${messageType}`} role="alert">
                  {message}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-5">
                <dl className="row mb-5">
                  <dt className="col-sm-3 fw-normal text-muted mb-2">Status</dt>
                  <dd className="col-sm-9">
                    <span className="text-capitalize">{format.formatResourceStatus(event.status)}</span>
                  </dd>
                  <dt className="col-sm-3 fw-normal text-muted mb-2">Priority</dt>
                  <dd className="col-sm-9">
                    <span className="text-capitalize">{event.priority}</span>
                  </dd>
                  <dt className="col-sm-3 fw-normal text-muted mb-2">Created</dt>
                  <dd className="col-sm-9">
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {event.created_at}
                    </Moment>
                    <span
                      className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                    <i className="bi bi-clock me-1"></i>
                    <span className="">
                      <TimeAgo date={event.created_at}/>
                    </span>
                  </span>
                    {/* {new Date(resource.created_at).toDateString()} */}
                  </dd>
                  <dt className="col-sm-3 fw-normal text-muted mb-2">
                    Last Update
                  </dt>
                  <dd className="col-sm-9">
                    {event.updated_at && (
                      <>
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {event.updated_at}
                        </Moment>
                        <span
                          className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                        <i className="bi bi-clock me-1"></i>
                        <span className="">
                          <TimeAgo date={event.updated_at}/>
                        </span>
                      </span>
                      </>
                    )}
                  </dd>
                  <dt className="col-sm-3 fw-normal text-muted mb-2">
                    Description
                  </dt>
                  <dd className="col-sm-9 mb-2">{event.description}</dd>
                  <dt className="col-sm-3 fw-normal text-muted mb-2">Slug</dt>
                  <dd className="col-sm-9">
                    <span ref={textSlugRef}>{event.slug}</span>
                    {/* <input name="slug" type="text" className="d-none" value={resource.slug} ref={textSlugRef} /> */}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${FE_WEBSITE_URL}event/${event.slug}`}
                      className="ms-4 me-1 small px-2 py-1 text-muted btn btn-link"
                    >
                      <i className="bi bi-link fs-5"></i>
                    </a>
                    <span
                      className="mx-1 px-2 py-1 small text-muted btn btn-link"
                      onClick={copyToClipboard}
                    >
                    {!copySuccess ? (
                      <i className="bi bi-clipboard fs-6"></i>
                    ) : (
                      <>
                        <i className="bi bi-check text-success fs-6"></i>
                        <span className="ms-2">{copySuccess}</span>
                      </>
                    )}
                  </span>
                  </dd>
                </dl>

              </div>
              <div className="col-md-6 ms-auto text-left">
                {event.images.length > 0 ? (
                  <div className="d-flex bg-gray">
                    {event.images.map((preview, index) => {
                      return (
                        <div className="text-center" key={index}>
                          <div className="shadow mx-2 position-relative">
                            <PreviewImage
                              image={`${MEDIA_URL}${preview.sizes[0].path}`}
                              index={index}
                              width={400}
                              disable={true}
                            />
                          </div>
                          <span
                            className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">
                          {preview.sizes[0].contentType}
                        </span>
                          {preview.sizes[0] && (
                            <>
                              <div
                                className="badge bg-secondary bg-opacity-10 text-secondary rounded-pill fw-normal pt-1 text-uppercase small mx-1 my-2">
                                <i className="bi bi-grid-1x2"></i>{" "}
                                {preview.sizes[0].width +
                                  " x " +
                                  preview.sizes[0].height}
                              </div>
                            </>
                          )}
                          {preview.sizes[1] &&
                            preview.sizes[1].size === "small" && (
                              <a
                                href={`${MEDIA_URL}${preview.sizes[1].path}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div
                                  className="badge bg-secondary bg-opacity-10 text-secondary rounded-pill fw-normal pt-1 text-uppercase small mx-1 my-2">
                                  <i className="bi bi-grid"></i>{" "}
                                  {preview.sizes[1].width +
                                    " x " +
                                    preview.sizes[1].height}
                                </div>
                              </a>
                            )}
                          {event.mainImage === index && (
                            <p className="pt-2 fw-semibold">
                              <small className="badge bg-success rounded-pill text-uppercase">
                                C
                              </small>
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : <LazyLoadImage src={placeholder} height={300}/>}
                <div>
                </div>
              </div>
            </div>
            <div className="row">
              {collections.map((collection, index) =>
                <div className={`col-2 mb-4 rgrid-item align-middle`}>
                  <div className={`rcontent position-relative p-3 border`}>
                    <div className="text-center">
                      <LazyLoadImage
                        alt={collection.collection_name}
                        src={placeholder}
                        height={160}
                        effect="blur"
                        className="img-preview"
                      />
                    </div>
                    <div className="d-flex justify-content-start align-items-center py-1">
                    <span>
                      {format.formatResourceStatusNoText(collection.status)}
                    </span>
                      <div className="small mt-1 ms-2">
                      <span className="ms-2">
                        {format.formatResourceOrientation(
                          collection.orientation
                        )}
                      </span>
                      </div>
                    </div>
                    <div>
                      <Link to={`/collections/view/${collection._id}`}>
                        <strong className="rname small">
                          {collection.collection_name}
                        </strong>
                      </Link>
                      <div className="d-flex align-items-center py-1 small">
                        <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                          {collection.slug.split("-").pop()}
                        </small>
                      </div>
                    </div>

                    <div className="d-none rdetail p-3 position-absolute top-0 bottom-0 start-0 end-0">
                      <div className="mb-1">
                        <Link to={`/collections/view/${collection._id}`}>
                          <strong className="rname small">
                            {collection.collection_name}
                          </strong>
                        </Link>
                      </div>
                      <p className="small text-muted mb-1">
                        {collection._id}
                      </p>
                      <div className="d-flex align-items-center py-1 small mb-1">
                        <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                          {collection.slug.split("-").pop()}
                        </small>
                      </div>
                      <div className="text-muted mb-1 small">
                      </div>

                      <div className="text-muted no-break mb-1 d-flex align-items-center">
                      <span
                        className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                        <i className="bi bi-clock me-1"></i>
                        <span className="">
                          <TimeAgo date={collection.created_at}/>
                        </span>
                      </span>
                        <Moment
                          format="YYYY/MM/DD HH:mm:ss"
                          className="small my-1"
                        >
                          {collection.created_at}
                        </Moment>
                      </div>
                      <div className="text-muted no-break mb-1 d-flex align-items-center">
                        {collection.updated_at && (
                          <>
                          <span
                            className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                            <i className="bi bi-clock me-1"></i>
                            <span className="">
                              <TimeAgo date={collection.updated_at}/>
                            </span>
                          </span>
                            <Moment
                              format="YYYY/MM/DD HH:mm:ss"
                              className="small my-1"
                            >
                              {collection.updated_at}
                            </Moment>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className="d-none p-3 item-action d-flex align-items-center justify-content-center position-absolute bottom-0 start-0 end-0">
                      <Link
                        target={`_blank`}
                        to={`/collections/edit/${collection._id}`}
                        className="btn btn-sm mx-1 rounded"
                      >
                        <i className="bi bi-pencil text-muted"></i>
                      </Link>
                      <Link
                        target={`_blank`}
                        to={`/collections/view/${collection._id}`}
                        className="btn btn-sm mx-1"
                      >
                        <i className="bi bi-eye text-muted"></i>
                      </Link>

                    </div>
                  </div>
                </div>)}
            </div>
          </> : <div>Not founded event</div>
      )}
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;

  const { message, messageType } = state.message;
  return {
    token,
    message,
    messageType
  };
}

export default connect(mapStateToProps)(EventDetail);
