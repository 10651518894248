import axios from "axios";
import { NODE_URL } from "../configs";
import helper from "../helpers/query";

const createEvent = (data) => {
  return axios.post(`${NODE_URL}/api/be/events`, data);
};

const getEvents = (query) => {
  //build query
  let newQuery = {};

  if (query.q) {
    newQuery.q = query.q;
  }

  if (query.created_by) {
    newQuery.created_by = query.created_by;
  }

  if (query.created_from) {
    newQuery.created_from = query.created_from;
  }

  if (query.created_to) {
    newQuery.created_to = query.created_to;
  }

  if (query.status) {
    newQuery.status = query.status;
  }

  if (query.page) {
    newQuery.offset = parseInt(query.page);
  }

  if (query.pageSize) {
    newQuery.limit = parseInt(query.pageSize);
  }

  if (query.sortby) {
    newQuery.sortby = query.sortby;
  }

  if (query.direction) {
    newQuery.direction = query.direction;
  }

  let q = helper.arrayToQueryString(newQuery);
  return axios.get(`${NODE_URL}/api/be/events?${q}`);
};

const getEventDetail = (id) => {
  return axios.get(`${NODE_URL}/api/be/events/${id}`);
};

const getCollectionOfEvent = (id) => {
  return axios.get(`${NODE_URL}/api/be/events/${id}/collections`);
};

const updateEventById = (id, data) => {
  return axios.put(`${NODE_URL}/api/be/events/${id}`, data);
};

const deleteEventById = (id) => {
  return axios.delete(`${NODE_URL}/api/be/events/${id}`);
};

const exportedObject = {
  createEvent,
  getEvents,
  getEventDetail,
  getCollectionOfEvent,
  updateEventById,
  deleteEventById
};

export default exportedObject;
